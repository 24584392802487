.modal {
  position: fixed;
  background-color: white;
  top: 0;
  left: 35%;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0 20px 30px 0px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgb(80, 154, 80);

}
.modalHeader p {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}
.modalContent{
   width: 500px;
  height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent img {
  max-width: 100%;
  max-height: 100%;
}
.modalFooter {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin: 10px;
}
.modalFooter button{
  background-color: rgb(80, 154, 80);
  padding: 10px;
  border-radius: 3px;
  color: white;
  border: none;
}
.modalFooter button:hover{
  box-shadow: 0 20px 30px 0px rgba(0, 0, 0, 0.3);
  background-color: rgb(112, 208, 112);
  color: rgb(72, 71, 71);
  cursor: pointer;
}